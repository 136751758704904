<template>
  <div>
    <districts-edit></districts-edit>
  </div>
</template>

<script>
import DistrictsEdit from "@/components/ecologist/districts/districtsEdit";
export default {
  name: "districtsEditView",
  components: { DistrictsEdit },
};
</script>

<style scoped></style>
